// Input
.input {
  height: 40px;
  padding: 2px 12px;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: var(--system-grey);
  }
}
