// Topic Overview
.topicOverview {
  // Header
  .header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 16px;

    // Title - topic name
    h1 {
      width: 100%;
      font-size: 60px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  // Articles
  .articles {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
}

// Search bar wrapper
.searchBar_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
