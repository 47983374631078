// Layout
.layout__content {
  padding: 96px 64px 16px;
  min-height: calc(100vh - 96px - 64px); // VP height - topbar height - footer height

  .layout__content_sizer {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 780px) {
  .layout__content {
    padding: 96px 16px 16px;
  }
}

// Footer
.layout__footer {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  // Links
  & > a {
    margin: 0 16px;
  }
}
