// Landing page
.landing {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 56px 16px;

  // Logo
  .logo {
    position: fixed;
    top: 20px;
    left: 32px;

    // Logo image
    & > img {
      height: 48px;
      width: 48px;
    }
  }

  // Call to action
  .cta {
    width: 100%;
    display: flex;
    align-items: center;

    // Text
    h1 {
      font-size: 60px;
      font-weight: 700;
      text-align: center;
    }
  }

  // Content row (text + image)
  .row {
    display: flex;
    justify-content: space-between;
    // padding: 10px 20px;
    box-sizing: border-box;

    // Children divs
    & > div {
      min-width: 0;
      flex: 1 0 0;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 10px 24px;
      box-sizing: border-box;

      h3,
      h6 {
        display: inline-block;
        max-width: 508px;
        margin-bottom: 36px;
      }

      // Sign up with Google button
      .signUpWithGoogle_wrapper {
        display: inline-block;
      }
    }

    // Spacing between children divs
    & > div:first-child {
      margin-right: 16px;
    }

    // Text container
    .text {
      // max-width: 300px;
      display: flex;
      align-items: center;
    }

    // Image container
    .image {
      max-width: 500px;
      max-height: 400px;
      img {
        width: 100%;
      }
    }
  }
}

// Fixed buttons (at top right corner of page)
.fixed_buttons {
  position: absolute;
  display: flex;
  top: 24px;
  right: 24px;

  // Take me to the app button
  .takeMeToTheApp {
    width: 176px;
    margin-left: 16px;
  }
}

// Responsiveness
@media only screen and (max-width: 1120px) {
  .row {
    width: 100%;
    flex-flow: column wrap;
    align-items: center;

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .text {
      flex-shrink: 1;
      order: 1;
    }

    .image {
      order: 2;
      margin-bottom: 36px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .fixed_buttons {
    flex-flow: column wrap;
    align-items: flex-end;

    // Take me to the app button
    .takeMeToTheApp {
      order: 1;
      margin-bottom: 8px;
    }

    // ProductHunt button
    & > a {
      order: 2;
    }
  }
}
