// Unsubscribe
.unsubscribe {
  height: 100vh;
  max-width: 1200px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 56px 16px;
  box-sizing: border-box;

  // Title: logo + "Muse"
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    // Logo
    img {
      height: 80px;
      position: relative;
      left: -12px;
      margin-right: 20px;
    }

    // "Muse"
    h1 {
      display: inline-block;
      font-size: 60px;
      font-weight: 300;
      font-family: Roboto, sans-serif;
    }
  }

  // Messages: "Unsubscribe" + note
  .message {
    width: 100%;
    margin-bottom: 36px;

    // "Unsubscribe"
    h3 {
      margin-bottom: 36px;
    }

    // Note
    h6 {
      max-width: 512px;
    }
  }

  // Buttons
  .buttons {
    margin-bottom: 16px;

    button:first-child {
      margin-right: 24px;
    }
  }
}

// Back to the app button
.backToTheApp {
  width: 176px;
  position: fixed;
  top: 24px;
  left: calc(100vw - 210px);
}

// Responsiveness
@media only screen and (max-width: 780px) {
  .title > img {
    height: 52px;
    width: 52px;
  }
}
