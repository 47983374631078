// Share
.share {
    height: 100vh;
  
    // Content
    .content {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
  
      // Title
      h3 {
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }
  
      // Description
      h4 {
        max-width: 600px;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;
      }

      // Share text
      .shareText {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 16px;
      }
  
      // Buttons
      .buttons {
        display: flex;
        align-items: center;

        button {
          margin: 0 64px;
          margin-bottom: 32px;
        }

        input {
          margin: 0 16px;
          margin-bottom: 32px;
        }
      }
    }
  }
  