// Topbar
.topbar {
  height: 64px;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 36px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: var(--brand-white);
  z-index: 99;

  .menuButton__wrapper {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    img {
      height: 16px;
      width: 16px;
    }
  }

  // Muse logo
  .logo {
    height: 40px;
    width: 40px;
    position: relative;
    top: 2px;
  }
}
