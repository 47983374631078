// Onboarding
.onboarding {
  height: 100vh;

  // Step progress
  .stepProgress_wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  // Content
  .content {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    // Step title
    h3 {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
    }

    // Step description
    h4 {
      max-width: 600px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 32px;
    }

    // Step subtext
    h5 {
      width: 100%;
      text-align: center;
      margin-bottom: 36px;
    }

    // Buttons
    .buttons {
      display: flex;
      align-items: center;

      button {
        margin: 0 64px;
      }
    }

    // Subscriptions step
    .subscriptionsStep {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-bottom: 48px;

      // Selected topics pills
      .topics {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 16px;

        // Topic card
        & > div {
          display: flex;
          justify-content: center;
          margin: 4px 8px;
          padding: 10px;
          border: 2px solid var(--system-green);
          border-radius: 10px;
          background-color: var(--system-light-green);
        }
      }

      // Custom topic selector
      .customTopicSelect {
        flex-grow: 1;
        max-width: 500px;
      }
    }
  }
}
