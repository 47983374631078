// Button classes
.subscribed {
  border: none;
  background-color: var(--system-light-green) !important;
  color: var(--brand-black) !important;

  &:hover {
    background-color: var(--system-red) !important;
  }
}
