// Search bar
.searchBar {
  max-width: 600px;
  flex-grow: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  // Input component
  .searchBar__input {
    flex-grow: 1;
    padding-right: 36px;
    border: 1px solid var(--system-grey);
  }

  // Search icon
  button {
    height: 16px;
    width: 16px;
    position: absolute;
    left: calc(100% - 32px);
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      height: 16px;
      width: 16px;
    }
  }
}
