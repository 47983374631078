// Article Card
.articleCard {
  margin: 24px 0;
  border: 1px solid var(--system-light-grey);
  border-radius: 4px;

  // Action Area
  & > button {
    width: 100%;
    padding: 0;
    cursor: pointer;

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      padding: 24px;
      background-color: var(--brand-white);
      border: none;
      box-sizing: border-box;

      // Placeholder book icon
      .imageWrapper {
        flex: 0 0 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;

        // Article thumbnail image
        .thumbnail {
          flex: 0 0 128px;
          height: 128px;
          object-fit: cover;
        }

        // Placeholder icon
        .placeholderIcon {
          height: 64px;
          width: 64px;
        }
      }

      // Content container
      .content {
        flex: 1 1 auto;

        // Article title
        h5 {
          text-align: left;
        }

        // Date and publisher
        h6 {
          font-size: 16px;
          font-weight: 400;
          font-family: Roboto, sans-serif;
          text-align: left;
        }

        // Article summary
        p {
          font-size: 16px;
          font-weight: 400;
          font-family: Roboto, sans-serif;
          text-align: left;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
}

// Responsiveness
@media only screen and (max-width: 640px) {
  .articleCard > button > div {
    flex-flow: column wrap;

    .imageWrapper {
      margin-bottom: 16px;
    }
  }
}
