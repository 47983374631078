// Button
.button {
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  border: 2px solid var(--brand-black);
  border-radius: 4px;
  padding: 6px 16px;
  box-sizing: border-box;
  text-transform: uppercase;
  cursor: pointer;

  // Variant: contained (default)
  &.contained {
    background-color: var(--brand-black);
    color: var(--brand-white);
  }

  // Variant: outlined
  &.outlined {
    background-color: var(--brand-white);
    color: var(--brand-black);
  }

  // Hover animation
  &:hover {
    background-color: var(--system-light-grey);
    transition: background-color 0.2s;
  }
}
