// Sidebar
.sidebar {
  width: 280px;
  flex-shrink: 0;

  // MUI Paper component
  .sidebar__paper {
    width: 280px;
  }

  // Header
  .sidebar__header {
    height: 64px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 16px;
  }
}
