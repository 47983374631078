:root {
  --brand-black: #000000;
  --brand-white: #ffffff;

  --system-grey: #cfcfcf;
  --system-light-grey: #d5d5d5;
  --system-green: #43bf49;
  --system-light-green: #c1fba4;
  --system-red: #df6873;
}

html {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  width: 100vw;
}

// Text styling
h1 {
  margin: 0;
  font-size: 60px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}

h2 {
  margin: 0;
  font-family: Roboto, sans-serif;
}

h3 {
  margin: 0;
  font-weight: 400;
  font-size: 32px;
  font-family: Roboto, sans-serif;
}

h4 {
  margin: 0;
  font-family: Roboto, sans-serif;
}

h5 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

h6 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 32px;
}

// Link styling
a {
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: var(--brand-black);
  text-decoration: none;
  cursor: pointer;
}

// Responsiveness
@media only screen and (max-width: 780px) {
  h1 {
    font-size: 36px !important;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 28px !important;
  }
  h4 {
    font-size: 24px !important;
  }
  h5 {
    font-size: 20px !important;
  }
  h6 {
    font-size: 16px !important;
  }
}
