// Daily dose
.dailyDose {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  // Title - "Your Daily Dose"
  h1 {
    width: 100%;
    font-size: 60px;
    font-weight: 300;
    text-align: center;
  }
}
