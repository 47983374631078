// Dashboard
.dashboard {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .searchBar_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
}
